import gql from 'graphql-tag';

export const ModernizedBreadcrumbsQuery = gql`
	query ModernizedBreadcrumbsQuery($spaceKey: String!, $contentId: ID, $skipContent: Boolean!) {
		space(key: $spaceKey) {
			alias
			id
			name
			icon {
				path
			}
			homepage {
				id
			}
		}
		content(id: $contentId, status: ["current", "draft", "archived"]) @skip(if: $skipContent) {
			nodes {
				id
				type
				subType
				status
				properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
					nodes {
						id
						key
						value
					}
				}
				ancestors {
					id
					type
					subType
					title
					status
					properties(keys: ["emoji-title-published"]) {
						nodes {
							id
							key
							value
						}
					}
				}
			}
		}
	}
`;
