import { WHITEBOARD_BOARD, SPACE_PAGES, SPACE_ARCHIVED_PAGES } from '@confluence/named-routes';

// only used for old breadcrumbs and can be removed after confluence_modernized_breadcrumbs have rolled out
export const getSkipContentAndTypes = (matchName?: string | null) => {
	const isArchivedPagesList = matchName === SPACE_ARCHIVED_PAGES.name;
	const isSpacePages = matchName === SPACE_PAGES.name;
	const isWhiteboard = matchName === WHITEBOARD_BOARD.name;

	const skipContent = isArchivedPagesList || isSpacePages;
	return { skipContent, isArchivedPagesList, isSpacePages, isWhiteboard };
};
