import type React from 'react';

import {
	SPACE_OVERVIEW,
	VIEW_BLOG,
	VIEW_PAGE,
	WHITEBOARD_BOARD,
	DATABASE_VIEW,
	EMBED_VIEW,
} from '@confluence/named-routes';
import type { generateSlug } from '@confluence/route-manager';
import type {
	BreadcrumbsQueryContentType,
	BreadcrumbsQueryAncestorType,
} from '@confluence/breadcrumbs-utils/entry-points/BreadcrumbsQueryTypes';

import type {
	ModernizedBreadcrumbsQuery_content_nodes as ModernizedBreadcrumbsQueryContentType,
	ModernizedBreadcrumbsQuery_content_nodes_ancestors as ModernizedBreadcrumbsQueryAncestorType,
	ModernizedBreadcrumbsQuery_content_nodes_ancestors_properties as ModernizedBreadcrumbsQueryPropertiesType,
} from './__types__/ModernizedBreadcrumbsQuery';

export const CONTENT_STATUS_ARCHIVED = 'archived';

type BuildContentPathType = {
	contentId?: string | null;
	contentSlug?: ReturnType<typeof generateSlug>;
	contentType: string | null;
	query?: any;
	spaceAlias?: string | null;
	spaceKey: string;
};

export const buildContentPath = ({
	contentId,
	contentSlug,
	contentType,
	query,
	spaceAlias,
	spaceKey,
}: BuildContentPathType) => {
	let route: typeof VIEW_PAGE;
	switch (contentType) {
		case 'overview':
			route = SPACE_OVERVIEW;
			break;
		case 'blogpost':
			route = VIEW_BLOG;
			break;
		case 'page':
			route = VIEW_PAGE;
			break;
		case 'whiteboard':
			route = WHITEBOARD_BOARD;
			break;
		case 'database':
			route = DATABASE_VIEW;
			break;
		case 'embed':
			route = EMBED_VIEW;
			break;
		case 'folder':
			return '#';
		default:
			throw new Error(`Received an unexpected content type: '${contentType}'`);
	}
	return route.toUrl({ contentId, contentSlug, spaceKey: spaceAlias || spaceKey }, { query });
};

export const filterAncestors = (
	{ ancestors, status }: BreadcrumbsQueryContentType | ModernizedBreadcrumbsQueryContentType,
	spaceHomepageId: string | null | undefined,
): BreadcrumbsQueryAncestorType[] | ModernizedBreadcrumbsQueryAncestorType[] => {
	if (!ancestors) {
		return [];
	}

	// If current page is archived, filter out non-archived ancestors
	if (status === CONTENT_STATUS_ARCHIVED) {
		ancestors = ancestors.filter(
			(ancestor) => ancestor && ancestor.status === CONTENT_STATUS_ARCHIVED,
		);
	} else {
		// technically array elements can be null
		ancestors = ancestors.filter((node) => node);
	}

	if (!ancestors.length) {
		return ancestors as BreadcrumbsQueryAncestorType[] | ModernizedBreadcrumbsQueryAncestorType[];
	}

	if (
		ancestors[0]!.id !== spaceHomepageId &&
		ancestors[ancestors.length - 1]!.id === spaceHomepageId
	) {
		return ancestors.reverse().slice(1) as
			| BreadcrumbsQueryAncestorType[]
			| ModernizedBreadcrumbsQueryAncestorType[];
	}
	if (ancestors[0]!.id === spaceHomepageId) {
		return ancestors.slice(1) as
			| BreadcrumbsQueryAncestorType[]
			| ModernizedBreadcrumbsQueryAncestorType[];
	}
	return ancestors as BreadcrumbsQueryAncestorType[] | ModernizedBreadcrumbsQueryAncestorType[];
};

export const getEmojiFromPublishedPageProperties = (
	properties: ModernizedBreadcrumbsQueryPropertiesType | null,
) => {
	if (!properties) {
		return undefined;
	}

	const values = properties?.nodes?.filter((node) => node?.key === 'emoji-title-published');
	return (values?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');
};

export const getEmojiFromDraftPageProperties = (
	properties: ModernizedBreadcrumbsQueryPropertiesType | null,
) => {
	if (!properties) {
		return undefined;
	}

	const values = properties?.nodes?.filter((node) => node?.key === 'emoji-title-draft');
	return (values?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');
};

export const getIsTextTruncated = (textRef: React.RefObject<HTMLElement>) => {
	if (textRef.current) {
		return textRef.current.scrollWidth > textRef.current.clientWidth;
	}
	return false;
};

export type BreadcrumbsDropdownItemType = {
	href: string;
	id: string;
	title: string;
	contentType: string;
	emoji: string | undefined;
	subType?: string | null;
};
