import type { MouseEvent } from 'react';
import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';

import { LoadableAfterPaint } from '@confluence/loadable';
import { ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';

import { GenericBreadcrumbItemTitles } from './constants';
import { BreadcrumbsAncestorsDropdownButtonPlaceholder } from './BreadcrumbsAncestorsDropdownButtonPlaceholder';
import { BreadcrumbsFolderPopup } from './BreadcrumbsFolderPopup';

export const LazyBreadrumbsEllipsisPopup = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsAncestorsDropdownButton" */ './BreadcrumbsAncestorsDropdownButton'
			)
		).BreadcrumbsAncestorsDropdownButton,
	loading: () => <BreadcrumbsAncestorsDropdownButtonPlaceholder />,
});

const separatorStyles = xcss({
	color: 'color.text.subtle',
	pointerEvents: 'none',
});

const Separator = () => (
	<Box xcss={separatorStyles} paddingInlineStart="space.100" paddingInlineEnd="space.100">
		/
	</Box>
);

const MAX_WIDTH_BREADCRUMBS = '570px';

const breadcrumbsStyles = xcss({
	minWidth: '0px', // needed for responsive breadcrumbs
	maxWidth: MAX_WIDTH_BREADCRUMBS,
});

const BREADCRUMBS_ITEM_MIN_WIDTH = '16px';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbLinkWrapper = styled.div({
	display: 'flex',
	minWidth: BREADCRUMBS_ITEM_MIN_WIDTH,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	div: {
		display: 'flex',
		minWidth: BREADCRUMBS_ITEM_MIN_WIDTH,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':only-child': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		a: {
			maxWidth: '100%', // breacrumbs should be full width if only 1 breadcrumb, such as in space overview
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbLink = styled.a<{ isCurrentPage?: boolean }>({
	textDecoration: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&, &&:hover, &&:visited': {
		color: token('color.text.subtle'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:active': {
		color: token('color.text.subtle'),
	},
	display: 'block',
	whiteSpace: 'nowrap',
	minWidth: BREADCRUMBS_ITEM_MIN_WIDTH,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isCurrentPage }) =>
		isCurrentPage
			? {
					cursor: 'default',
					maxWidth: '300px',
					'&&, &&:visited, &&:active': {
						textDecoration: 'none',
					},
				}
			: {
					cursor: 'pointer',
					maxWidth: '150px',
				},
});

const folderItemStyles = xcss({
	flexShrink: 1,
	display: 'inline-block',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FolderItem = styled.button<{ isSelected?: boolean; compact?: boolean }>({
	color: token('color.text.subtle'),
	fontWeight: token('font.weight.regular'),
	backgroundColor: 'transparent',
	border: '1px transparent',
	borderRadius: '4px',
	padding: '4px 8px',
	cursor: 'pointer',
	margin: `${token('space.negative.025')} ${token('space.negative.075')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: (props) => (props.compact ? '12px' : '14px'),
	minWidth: BREADCRUMBS_ITEM_MIN_WIDTH,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isSelected }) =>
		isSelected && {
			color: token('color.text.selected'),
			backgroundColor: token('color.background.selected'),
			'&&:hover': {
				backgroundColor: token('color.background.selected.hovered'),
			},
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadrumbsEllipsisWrapper = styled.span({
	display: 'flex',
});

const BreadcrumbItem = ({
	title,
	href,
	onClick,
	ancestors,
	isCurrentPage = false,
	contentType,
	contentId,
	compact,
}: {
	title: string;
	href: string;
	onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
	ancestors: { href: string; id: string; title: string }[];
	isCurrentPage?: boolean;
	contentType?: string;
	contentId?: string;
	compact?: boolean;
}) => {
	const intl = useIntl();
	const isFolder = contentType === 'folder';
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClickFolderDropdownItem = (content, depth) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'breadcrumb',
				source: 'breadcrumbsFolderButton',
				attributes: {
					breadcrumbsTotal: ancestors.length + 1,
					origin: 'view-page',
					nestedDepth: depth + 2,
					destination: GenericBreadcrumbItemTitles.Ancestor,
					titleLength: content.title.length,
				},
			},
		}).fire();
	};

	return (
		<BreadcrumbLinkWrapper>
			{isFolder && contentId ? (
				<BreadcrumbsFolderPopup
					contentId={contentId}
					isOpen={isOpen}
					onClose={() => {
						setIsOpen(false);
					}}
					placement="bottom-start"
					trigger={(triggerProps) => (
						<Tooltip content={title} hideTooltipOnClick>
							<FolderItem
								{...triggerProps}
								onClick={() => setIsOpen((v) => !v)}
								isSelected={isOpen}
								compact={compact}
							>
								<Inline space="space.050" alignBlock="center">
									<ContentTreeIcon
										type={contentType}
										label={intl.formatMessage(i18n.folder)}
										color={isOpen ? token('color.text.selected') : undefined}
									/>
									<Box as="span" xcss={folderItemStyles}>
										{title}
									</Box>
								</Inline>
							</FolderItem>
						</Tooltip>
					)}
					handleClickFolderDropdownItem={handleClickFolderDropdownItem}
				/>
			) : (
				<Tooltip content={title} hideTooltipOnClick>
					<BreadcrumbLink href={href} onClick={onClick} isCurrentPage={isCurrentPage}>
						{title}
					</BreadcrumbLink>
				</Tooltip>
			)}
		</BreadcrumbLinkWrapper>
	);
};

type AnalyticsAttributes = {
	nestedDepth: number;
	destination: GenericBreadcrumbItemTitles;
	titleLength: number;
};

type BreadcrumbsInnerProps = {
	ancestors: {
		href: string;
		id: string;
		title: string;
	}[];
	contentTitle: string;
	spaceName: string;
	spaceOverviewPath: string;
	compact?: boolean;
};

export function BreadcrumbsPicker({
	ancestors,
	contentTitle,
	spaceName,
	spaceOverviewPath,
	compact,
}: BreadcrumbsInnerProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onBreadcrumbClick = (attributes: AnalyticsAttributes) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'breadcrumb',
				source: 'containerNavigation',
				attributes: {
					breadcrumbsTotal: ancestors.length + 1,
					origin: 'view-page',
					...attributes,
				},
			},
		}).fire();
	};

	const handleClickAncestorBreadcrumb = (title, depth) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'breadcrumb',
				source: 'breadcrumbsEllipsisButton',
				attributes: {
					breadcrumbsTotal: ancestors.length + 1,
					origin: 'view-page',
					nestedDepth: depth + 2,
					destination: GenericBreadcrumbItemTitles.Ancestor,
					titleLength: title.length,
				},
			},
		}).fire();
	};

	const renderInnerBreadcrumbs = (ancestors) => {
		const dropdownAncestors = ancestors.slice(0, ancestors.length - 1);
		const lastInnerAncestor = ancestors[ancestors.length - 1];

		return (
			<>
				{dropdownAncestors.length > 0 && (
					<>
						<Separator />
						<BreadrumbsEllipsisWrapper>
							<LazyBreadrumbsEllipsisPopup
								ancestors={dropdownAncestors}
								handleClickAncestorBreadcrumb={handleClickAncestorBreadcrumb}
							/>
						</BreadrumbsEllipsisWrapper>
					</>
				)}
				{lastInnerAncestor && (
					<>
						<Separator />
						<BreadcrumbItem
							contentId={lastInnerAncestor.id}
							title={lastInnerAncestor.title}
							href={lastInnerAncestor.href}
							onClick={() =>
								handleClickAncestorBreadcrumb(lastInnerAncestor.title, ancestors.length - 1)
							}
							ancestors={ancestors}
							contentType={lastInnerAncestor.contentType}
							compact={compact}
						/>
					</>
				)}
			</>
		);
	};

	return (
		<Flex xcss={breadcrumbsStyles}>
			<BreadcrumbItem
				title={spaceName}
				href={spaceOverviewPath}
				onClick={onBreadcrumbClick({
					nestedDepth: 1,
					destination: GenericBreadcrumbItemTitles.Space,
					titleLength: spaceName.length,
				})}
				ancestors={ancestors}
				compact={compact}
			/>

			{renderInnerBreadcrumbs(ancestors)}

			{contentTitle && (
				<>
					<Separator />
					<BreadcrumbItem
						title={contentTitle}
						href="#"
						onClick={onBreadcrumbClick({
							nestedDepth: ancestors.length + 2,
							destination: GenericBreadcrumbItemTitles.ContentTitle,
							titleLength: contentTitle.length,
						})}
						ancestors={ancestors}
						isCurrentPage
						compact={compact}
					/>
				</>
			)}
		</Flex>
	);
}

const i18n = defineMessages({
	folder: {
		id: 'breadcrumbs.picker.folder',
		defaultMessage: 'Folder',
		description: 'Label for folder icon in breadcrumbs',
	},
});
