import React, { useState, useContext, useRef, useEffect } from 'react';
import { css } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { SpotlightTarget } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlaskit/platform-feature-flags';

import { LIVE_DOC_MODES_TOGGLE_TARGET } from '@confluence/onboarding-live-doc-changeboarding/entry-points/LiveDocSpotlightsTour';
import { LoadableLazy, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useSpaceDetail, useSpaceHomepageId } from '@confluence/space-utils';
import {
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
	BREADCRUMBS_EXPERIENCE,
	BREADCRUMBS_DROPDOWN_EXPERIENCE,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { ContentIcon } from '@confluence/icons/entry-points/ContentIcon';

import { preloadBreadcrumbsDropdown } from './preloadBreadcrumbsDropdown';
import { getIsTextTruncated, buildContentPath } from './breadcrumbsHelpers';

const BreadcrumbsDropdownContent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsDropdownContent" */ './BreadcrumbsDropdownContent'
			)
		).BreadcrumbsDropdownContent,
});

const BreadcrumbsPlaceholder = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsPlaceholder" */ './BreadcrumbsPlaceholder'
			)
		).BreadcrumbsPlaceholder,
});

const separatorStyles = css({
	color: token('color.text.subtle'),
	pointerEvents: 'none',
	flexShrink: '0',
	marginRight: token('space.050'),
	marginLeft: token('space.050'),
});

const hideSeparatorStyles = css({
	[media.below.xs]: {
		display: 'none',
	},
});

const breadcrumbsStyles = xcss({
	minWidth: '32px', // needed for responsive breadcrumbs, 32px is the width of the chevron button
	alignItems: 'center',
	flex: '0 1 auto',
	gap: 'space.100',
});

const fadedSpaceNameTextStyles = css({
	maskImage: 'linear-gradient(to right, black 60%, transparent 100%)',
	WebkitMaskImage: 'linear-gradient(to right, black 60%, transparent 100%)',
});

const spaceNameTextStyles = css({
	textOverflow: 'ellipsis',
	width: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '100%',
	font: token('font.body'),
});

const noEllipsisStyles = css({
	textOverflow: 'unset',
});

const breadcrumbDropdownTriggerStyles = css({
	display: 'inline-block',
	cursor: 'pointer',
	flexShrink: 0,
	width: '100%',
	borderRadius: '4px',
	border: 'transparent',
	backgroundColor: token('color.background.neutral.subtle'),
	color: token('color.text.subtlest'),
	'&:hover': {
		textDecoration: 'underline',
		color: token('color.text.subtle'),
	},
	'&:focus': {
		color: token('color.text.subtle'),
	},
	'&:active': {
		color: token('color.text'),
	},
	padding: '0',
	paddingBlock: '0',
	paddingInline: '0',
});

const maxWidthBreadcrumbsDropdownTriggerStyles = css({
	maxWidth: '240px',
	[media.below.md]: {
		maxWidth: '92px',
	},
	width: 'unset',
});

const maxWidthOnlyContentTitleTriggerStyles = css({
	maxWidth: '100%',
	[media.below.md]: {
		maxWidth: '100%',
	},
	textOverflow: 'ellipsis',
});

const currentPageBreadcrumbStyles = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: token('font.heading.xsmall'),
	minWidth: '24px',
	color: token('color.text'),
	[media.below.xs]: {
		display: 'none',
	},
});

const currentPageLinkBreadcrumbStyles = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: token('font.heading.xsmall'),
	minWidth: '24px',
	color: token('color.text'),

	[media.below.xs]: {
		display: 'none',
	},

	'&:visited': {
		color: token('color.text'),
	},

	'&:visited:active': {
		color: token('color.text'),
	},
});

const chevronHoverButtonStyles = xcss({
	width: '32px',
	minWidth: '32px',
	height: '32px',
	borderRadius: '4px',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

export enum BREADCRUMBS_MODES {
	ONLY_CONTENT_TITLE = 'ONLY_CONTENT_TITLE',
	ONLY_SPACE_NAME = 'ONLY_SPACE_NAME',
	FULL = 'FULL',
	HIDDEN = 'HIDDEN',
}

type BreadcrumbsWithSpaceNameDropdownProps = {
	contentTitle?: string;
	spaceKey: string;
	contentId?: string;
	testId?: string;
	showInlineRename?: boolean;
	shouldDisableMoveButton?: boolean;
	displayMode: BREADCRUMBS_MODES;
	contentLink?: string;
	contentType?: string;
	subType?: string;
};

export const BreadcrumbsWithSpaceNameDropdown = ({
	contentTitle,
	spaceKey,
	contentId,
	testId = 'breadcrumbs-with-space-name-dropdown',
	showInlineRename = false,
	shouldDisableMoveButton = false,
	displayMode,
	contentLink,
	contentType,
	subType,
}: BreadcrumbsWithSpaceNameDropdownProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isBreadcrumbsPopupOpen, setIsBreadcrumbsPopupOpen] = useState(false);
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { data: spaceData, error } = useSpaceDetail(spaceKey);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const breadcrumbsButtonTextRef = useRef<HTMLDivElement | null>(null);
	const [spaceNameIsTruncated, setSpaceNameIsTruncated] = useState(false);
	const spaceHomepageId = useSpaceHomepageId(spaceKey);
	const { push } = useRouteActions();
	const isSideNavCollapsed =
		confluenceLocalStorageInstance.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
		) === 'collapsed';

	useEffect(() => {
		// whenever the width of the space name breadcrumbs changes, check if it is truncated to apply the fade css
		const resizeObserver = new ResizeObserver(() => {
			setSpaceNameIsTruncated(getIsTextTruncated(breadcrumbsButtonTextRef));
		});

		const currentBreadcrumb = breadcrumbsButtonTextRef.current;

		if (currentBreadcrumb) {
			resizeObserver.observe(currentBreadcrumb);
		}

		return () => {
			if (currentBreadcrumb) {
				resizeObserver.unobserve(currentBreadcrumb); // Use the stored reference
			}
			resizeObserver.disconnect();
		};
	}, [spaceKey, isSideNavCollapsed]);

	// Note don't check for loading as when refreshing data we will get data={...} and loading=true
	// In this case if we display the placeholder it will flash before the data is displayed
	// We should continue render with existing data and then update when the new data is available
	if (error || !spaceData) {
		return (
			<>
				{error && (
					<ErrorDisplay error={error}>
						<ExperienceFailure name={BREADCRUMBS_EXPERIENCE} error={error} />
					</ErrorDisplay>
				)}
				<BreadcrumbsPlaceholder />
			</>
		);
	}
	const spaceName = spaceData.name || spaceData.alias || spaceKey;

	const contentTitleToRender =
		contentTitle === '' ? intl.formatMessage(i18n.untitledPage) : contentTitle;
	const shouldOpenDropdown = contentTitleToRender || showInlineRename;
	const shouldShowCurrentContentTitle = contentTitleToRender && !showInlineRename;

	const spaceOverviewPath = buildContentPath({
		contentType: 'overview',
		query: spaceHomepageId
			? { [SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY]: spaceHomepageId }
			: undefined,
		spaceAlias: spaceData.alias,
		spaceKey,
	});

	const handleSpaceNameBreadcrumbClick = () => {
		if (shouldOpenDropdown) {
			if (!isBreadcrumbsPopupOpen) {
				experienceTracker.start({
					name: BREADCRUMBS_DROPDOWN_EXPERIENCE,
				});

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'opened',
						actionSubject: 'breadcrumbsDropdown',
						source: 'breadcrumbs',
						attributes: {
							isContentWrapper: fg('confluence_frontend_object_header'),
						},
					},
				}).fire();
			}

			setIsBreadcrumbsPopupOpen(!isBreadcrumbsPopupOpen);
		} else {
			push(spaceOverviewPath);
		}
	};

	const popupContent = () => {
		return (
			<BreadcrumbsDropdownContent
				spaceKey={spaceKey}
				contentId={contentId}
				closeDropdownOnClickOfBreadcrumbItem={closeDropdownOnClickOfBreadcrumbItem}
				spaceOverviewPath={spaceOverviewPath}
				shouldDisableMoveButton={shouldDisableMoveButton}
				contentTitle={contentTitleToRender}
			/>
		);
	};

	const closeDropdownOnClickOfBreadcrumbItem = () => {
		setIsBreadcrumbsPopupOpen(false);
	};

	const preloadBreadcrumbsDropdownOnSpaceNameHover = () => {
		void preloadBreadcrumbsDropdown({ spaceKey, contentId });
	};

	const renderContentTitle = () => {
		const titleElement = contentLink ? (
			<a css={currentPageLinkBreadcrumbStyles} href={contentLink}>
				{contentTitleToRender}
			</a>
		) : (
			<div css={currentPageBreadcrumbStyles}>{contentTitleToRender}</div>
		);

		const wrappedTitleElement = fg('confluence_frontend_object_header') ? (
			titleElement
		) : (
			<SpotlightTarget name={LIVE_DOC_MODES_TOGGLE_TARGET}>{titleElement}</SpotlightTarget>
		);

		return (
			<>
				{contentType === 'page' && (
					<ContentIcon type={contentType} subType={subType} color={token('color.text')} />
				)}
				{wrappedTitleElement}
			</>
		);
	};

	const renderSpaceName = () => {
		return (
			<div
				ref={breadcrumbsButtonTextRef}
				css={[
					spaceNameTextStyles,
					shouldOpenDropdown && noEllipsisStyles,
					shouldOpenDropdown && spaceNameIsTruncated && fadedSpaceNameTextStyles,
				]}
			>
				{spaceName}
			</div>
		);
	};

	const renderSeparator = () => {
		return <div css={[separatorStyles, !showInlineRename && hideSeparatorStyles]}>/</div>;
	};

	return (
		<Flex xcss={breadcrumbsStyles} testId={testId}>
			<Popup
				isOpen={isBreadcrumbsPopupOpen}
				onClose={() => setIsBreadcrumbsPopupOpen(false)}
				placement="bottom-start"
				content={popupContent}
				trigger={(triggerProps) => (
					<button
						css={[
							breadcrumbDropdownTriggerStyles,
							shouldOpenDropdown && maxWidthBreadcrumbsDropdownTriggerStyles,
							displayMode === BREADCRUMBS_MODES.ONLY_CONTENT_TITLE &&
								maxWidthOnlyContentTitleTriggerStyles,
						]}
						onClick={handleSpaceNameBreadcrumbClick}
						onMouseEnter={preloadBreadcrumbsDropdownOnSpaceNameHover}
						aria-label="Breadcrumbs"
						{...triggerProps}
						{...ssrPlaceholderIdProp}
					>
						<Flex alignItems="center" gap="space.100">
							{shouldOpenDropdown && (
								<Tooltip
									content={intl.formatMessage(i18n.breadcrumbsChevronButtonTooltip)}
									hideTooltipOnClick
								>
									{(tooltipProps) => (
										<Box xcss={chevronHoverButtonStyles} {...tooltipProps}>
											<ChevronDownIcon label="" color={token('color.icon.subtle')} />
										</Box>
									)}
								</Tooltip>
							)}
							{displayMode === BREADCRUMBS_MODES.ONLY_CONTENT_TITLE
								? showInlineRename
									? null
									: renderContentTitle() // for content types in ONLY_CONTENT_TITLE mode, the inline rename title component will show already
								: renderSpaceName()}
						</Flex>
					</button>
				)}
			/>
			{displayMode === BREADCRUMBS_MODES.FULL && (
				<>
					{shouldOpenDropdown && renderSeparator()}
					{shouldShowCurrentContentTitle && renderContentTitle()}
				</>
			)}
			<ExperienceSuccess name={BREADCRUMBS_EXPERIENCE} />
		</Flex>
	);
};

const i18n = defineMessages({
	untitledPage: {
		id: 'breadcrumbs.dropdown.untitled-page.default-title',
		defaultMessage: 'Untitled',
		description: 'Title for an untitled draft page, draft blogpost, or live page for breadcrumbs',
	},
	breadcrumbsChevronButtonTooltip: {
		id: 'breadcrumbs.dropdown.button.chevron.tooltip',
		defaultMessage: 'Show navigation path',
		description:
			'Tooltip when hovering over the chevron button that will open the breadcrumbs dropdown onclick',
	},
});
